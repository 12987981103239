import React, { useEffect, useRef } from "react";
import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StickyNavbar from "../../components/StickyNavbar";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar
} from "@fortawesome/free-solid-svg-icons";
// import SideDropdown from '../../components/SideDropdown';
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
import CourseOpportunities from '../../components/CourseOpportunities'

const SAP = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: "a2kql8BWKwI", // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: "a2kql8BWKwI", // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is SAP?",
        "What is SAP PM?",
        "What is SAP HANA?",
        "SAP course",
        "SAP certification",
        "SAP jobs in india",
        "SAP jobs in Thane",
      ],
    },
    {
      title: "Data Migration and Cleansing",
      points: [
        "Analyze existing data and identify data migration requirements.",
        "Cleanse and validate data to ensure accuracy and completeness.",
        "Develop a data migration strategy and execute the migration plan.",
      ],
    },
    {
      title: "Master Data Management",
      points: [
        "Define and standardize equipment, functional locations, and work center master data.",
        "Populate master data with relevant information.",
        "Implement governance processes to maintain data integrity.",
      ],
    },
    {
      title: "Mobile Workforce Enablement",
      points: [
        "Evaluate and implement mobile solutions for field technicians.",
        "Enable remote access to work orders, data collection, and reporting.",
        "Train the workforce on mobile tools and processes.",
      ],
    },
    {
      title: "Integration with External Systems",
      points: [
        "Integrate SAP PM with other enterprise systems.",
        "Implement interfaces with IoT devices for real-time equipment monitoring.",
        "Establish communication with external service providers.",
      ],
    },
    {
      title: "Performance Monitoring and Optimization",
      points: [
        "Implement key performance indicators (KPIs) for measuring PM module effectiveness.",
        "Continuously monitor and analyze system performance.",
        "Identify opportunities for optimization and enhancement.",
      ],
    },
    {
      title: "Regulatory Compliance and Reporting",
      points: [
        "Ensure SAP PM processes align with industry regulations and standards.",
        "Implement reporting tools for regulatory compliance documentation.",
        "Regularly audit and update compliance measures.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const modulesData = [
    {
      title: "SAP",
      points: [
        "IT Accurate Training in Thane offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/best-sap-training",
    },
    {
      title: "SAP BASIS",
      points: [
        "IT Accurate Training in Thane offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-Thane",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. IT Accurate Institute in Thane offers comprehensive training in SAP MM for effective utilization.",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by IT Accurate Institute Thane, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations.",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. IT Accurate Institute in Thane is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. IT Accurate Institute in Thane likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. IT Accurate Institute Thane is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const syllabus = [
    {
      title: "Introduction of SAP PM",
      subpoints: [
        "Overview of SAP PM module",
        "Integration with other SAP modules",
      ],
    },
    {
      title: "Master Data in SAP PM",
      subpoints: [
        "Equipment Master",
        "Functional Locations",
        "Bill of Materials (BOM)",
        "Work Centers",
      ],
    },
    {
      title: "Maintenance Processing",
      subpoints: [
        "Maintenance Notifications",
        "Maintenance Orders",
        "Order Types and Categories",
        "Order Planning and Scheduling",
      ],
    },
    {
      title: "Preventive Maintenance",
      subpoints: [
        "Time-based and Performance-based Maintenance Plans",
        "Maintenance Task Lists",
        "Maintenance Strategies",
      ],
    },
    {
      title: "Breakdown Maintenance",
      subpoints: [
        "Handling unplanned maintenance events",
        "Breakdown Maintenance Orders",
      ],
    },
    {
      title: "Work Clearance Management",
      subpoints: ["Lockout/Tagout processes", "Permit to Work"],
    },
    {
      title: "Technical Objects",
      subpoints: [
        "Serial Numbers",
        "Measuring Points and Counters",
        "Document Management",
      ],
    },
  ];

  const faqs = [
    {
      question: "What is SAP?",
      answer:
        "SAP stands for Systems, Applications, and Products in Data Processing. It is a leading enterprise resource planning (ERP) software used by businesses to manage various aspects such as finances, sales, procurement, human resources, and more.",
    },
    {
      question: "What are the benefits of SAP certification?",
      answer:
        "SAP certification enhances your skills and credibility in using SAP software, leading to better job opportunities, higher salaries, and increased chances of career advancement in the IT industry.",
    },
    {
      question: "What are the different modules available in SAP?",
      answer:
        "SAP offers a wide range of modules catering to different business functions. Some popular modules include SAP Finance (FI), SAP Sales and Distribution (SD), SAP Material Management (MM), SAP Human Capital Management (HCM), and SAP Production Planning (PP).",
    },
    {
      question: "How long does it take to learn SAP?",
      answer:
        "The time required to learn SAP varies depending on factors such as your prior experience, the specific modules you want to learn, and the intensity of your training. Generally, it can take anywhere from a few weeks to several months to gain proficiency in SAP.",
    },
    {
      question: "Is SAP difficult to learn for beginners?",
      answer:
        "SAP can be challenging for beginners due to its complexity and the vast range of functionalities it offers. However, with dedication, structured learning, and hands-on practice, beginners can gradually master SAP concepts and become proficient users.",
    },
    {
      question: "What are SAP implementation challenges?",
      answer:
        "SAP implementation challenges may include high costs, integration complexities with existing systems, resistance from employees to adapt to new processes, data migration issues, and ensuring successful user adoption and training.",
    },
    {
      question: "What is SAP HANA?",
      answer:
        "SAP HANA is an in-memory database and application platform that accelerates data processing and analytics in real-time. It enables businesses to access and analyze large volumes of data swiftly, leading to faster decision-making and improved business agility.",
    },
    {
      question: "What are the job roles in SAP?",
      answer:
        "Job roles in SAP vary based on expertise and specialization. Some common SAP job roles include SAP Consultant, SAP Developer, SAP Basis Administrator, SAP Project Manager, and SAP Functional Analyst, each focusing on different aspects of SAP implementation, customization, and support.",
    },
  ];

  const whatsData = [
    {
      title: "What is SAP ?",
      points: [
        "SAP Stands for system application and products in data processing. SAP is the most used ERP software.",
        "The various products and modules offered by SAP ERP enable customers to perform day to day business processess, such as sales, production, accounting, HR and finance.",
        "For every business processess SAP has their specific modules like SAP MM, SAP FICO, SAP HCM, SAP PP and many more.",
        "Sapalogy provides real time project based SAP training in all SAP modules.",
        "IT background, non IT background, Freshers, experience can start their carrer in SAP irrespective of their background.",
        "Start by understanding the basics of platform, get training with sapalogy and join communities and placement oriented training of salesforce will give you hand experiences.",
        "Becoming a SAP Pro in a specific module is achievable through unwavering hard work, dedication, and commitment.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //     { Image: rev1, alt: "rev1" },
  //     { Image: rev2, alt: "rev1" },
  //     { Image: rev3, alt: "rev1" },
  //     { Image: rev4, alt: "rev1" },
  //     { Image: rev5, alt: "rev1" },
  // ]
  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  return (
    <div className="devops-page">
      {/* <SideDropdown roadmapData={modulesData} /> */}
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>IT Accurate Best SAP Training in Thane -Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">5907 Learners</span>
          </div>
          <p>
            IT Accurate provides top-notch SAP training in Thane, tailored for
            both beginners and experienced professionals. With expert guidance
            and a 100% placement assistance guarantee, our course thoroughly
            covers every technical aspect, ensuring students gain valuable,
            real-world experience.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program will not only provide
            you with a certificate but also with knowledge equivalent to around
            2+ years of field experience. We value your time as much as ours.
            Hence, we’ve designed our SAP training with S/4 HANA to be completed
            in just 4 months.
          </p>
          <p>
            Our hands-on, job-focused training program offers more than just a
            certification—it equips you with knowledge comparable to over 8
            years of industry experience. We respect your time as much as ours,
            which is why we've designed our SAP with S/4 HANA course to be
            completed in just 4 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <StickyNavbar name="Sap" />
      <WhatsDevOps whatsData={whatsData} />

      {/* <Roadmap heading="Roadmap to Learn SAP" roadmapData={roadmapData} />
      <Syllabus heading="SAP Training Syllabus" Syllabus={syllabus} /> */}
      {/* <Syllabus heading="S4 HANA Training Syllabus" Syllabus={syllabus2} /> */}
      <KeyFeatures heading="SAP Training" keyFeatures={keyFeatures} />

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">SAP CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification serves as proof that the
                courses were completed through IT Accurate.
              </li>
              <li>
                The SAP S/4HANA certification offered by IT Accurate equips you
                with valuable skills, enhancing your competitiveness in the job
                market.
              </li>
              <li>
                IT Accurate provides comprehensive support for your SAP S/4HANA
                global certification, with a 100% passing guarantee on exams
                like SAP Certification, SAP S/4HANA Platform Development
                Certification, and other global certifications.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Roadmap heading="SAP modules" roadmapData={modulesData} />

      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}
      <CourseOpportunities pageName="SAP" />

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP ABAP Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <MarqueeLogos />
    </div>
  );
};

export default SAP;
