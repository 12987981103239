import React from "react";
import { Menus } from "./Navbar/utils";
import Logo from "../assets/it accurate logo.png";
import DesktopMenu from "./Navbar/DesktopMenu";
import MobMenu from "./Navbar/MobMenu";

const Navbar = () => {
  return (
    <div>
      <header id="navbar" className="h-16 text-[15px] z-[999] fixed inset-0 flex-center bg-[#fff] ">
        <nav className=" px-3.5 md:ml-[3rem] flex-center-between w-full max-w-7xl mx-auto">
          <div className="flex-center gap-x-3 z-[999] relative">
            <a href="/"><img src={Logo} alt="" className="w-[170px]" /></a>

          </div>

          <ul className="gap-x-1 lg:flex-center hidden">
            {Menus.map((menu) => (
              <DesktopMenu menu={menu} key={menu.name} />
            ))}
            <button
              aria-label="sign-in"
              className="z-[999] relative px-3 py-2.5 shadow rounded-xl flex-center"
              style={{
                background: "linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%)"
              }}
            >
              <a href="tel:+917972849125" className="text-white font-semibold">
                +91-9175978889
              </a>

            </button>
          </ul>
          <div className="flex-center gap-x-5">
            <div className="lg:hidden">
              <MobMenu Menus={Menus} />
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
