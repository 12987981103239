import React, { useEffect, useRef } from "react";
import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
// import IMG from '../../assets/logo.svg'
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
// import PageForm from '../../components/PageForm';
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import StickyNavbar from "../../components/StickyNavbar";
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// import SideDropdown from '../../components/SideDropdown';
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
import CourseOpportunities from '../../components/CourseOpportunities'

const SAPBASIS = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  //   useEffect(() => {
  //     //Function to create the YouTube player
  //    const createPlayer = () => {
  //      if (window.YT && window.YT.Player) {
  //        playerRef.current = new window.YT.Player(videoRef.current, {
  //          videoId: 'VhmCwdaf63k', // Updated YouTube video ID
  //          events: {
  //            onReady: (event) => {
  //              // event.target.mute(); // Mute the video to allow autoplay
  //              event.target.playVideo();
  //            },
  //          },
  //          playerVars: {
  //            loop: 1,
  //            controls: 0, // Hide video controls
  //            modestbranding: 1, // Minimal YouTube branding
  //            playlist: 'VhmCwdaf63k', // Required for looping
  //          },
  //        });
  //      }
  //    };

  //    // Load YouTube IFrame API if it's not already loaded
  //    if (!window.YT) {
  //      const tag = document.createElement('script');
  //      tag.src = 'https://www.youtube.com/iframe_api';
  //      const firstScriptTag = document.getElementsByTagName('script')[0];
  //      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //      // Set up callback for when the API is ready
  //      window.onYouTubeIframeAPIReady = () => {
  //        createPlayer();
  //      };
  //    } else {
  //      createPlayer();
  //    }
  //  }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is SAP?",
        "What is SAP BASIS?",
        "What is SAP HANA?",
        "SAP course",
        "SAP certification",
        "SAP jobs in india",
        "SAP jobs in nagpur",
      ],
    },
    {
      title: " Understanding SAP BASIS Fundamentals",
      points: [
        "Learn the architecture of SAP systems.",
        "Understand the role and responsibilities of a BASIS administrator.",
        "Familiarize yourself with basic SAP terminology.",
      ],
    },
    {
      title: "Installation and Configuration",
      points: [
        "Install and configure SAP systems and databases.",
        "Set up SAP landscapes and perform system copies.",
        "Configure system parameters and profiles.",
      ],
    },
    {
      title: "User and Authorization Management",
      points: [
        "Create and manage user accounts.",
        "Assign roles and authorizations.",
        "Implement security policies and procedures.",
      ],
    },
    {
      title: "System Monitoring and Performance Tuning",
      points: [
        "Monitor system performance and health.",
        "Use tools like SAP Solution Manager for monitoring.",
        "Perform regular system health checks and tuning.",
      ],
    },
    {
      title: "Backup and Recovery Procedures",
      points: [
        "Implement backup strategies for SAP systems.",
        "Perform regular backups and data recovery tests.",
        "Ensure data integrity and availability.",
      ],
    },
    {
      title: "Upgrades and Patch Management",
      points: [
        "Plan and execute SAP system upgrades.",
        "Apply patches and updates to SAP software.",
        "Test and validate system changes before deployment.",
      ],
    },
    {
      title: "Resume preparation",
      points: [
        "Include keywords",
        "How to prepare reume",
        "How to prepare resume for freshers.",
        "Show impact",
        "Include soft skills",
        "Be unique",
      ],
    },
  ];

  const modulesData = [
    {
      title: "SAP",
      points: [
        "IT Accurate Training in Thane offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/best-sap-training",
    },
    {
      title: "SAP BASIS",
      points: [
        "IT Accurate Training in Thane offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-Thane",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. IT Accurate Institute in Thane offers comprehensive training in SAP MM for effective utilization.",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by IT Accurate Institute Thane, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations.",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. IT Accurate Institute in Thane is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. IT Accurate Institute in Thane likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. IT Accurate Institute Thane is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const syllabus = [
    {
      title: "Introduction To SAP",
      subpoints: [
        "Introduction of ERP & SAP",
        "What is SAP?",
        "SAP History",
        "SAP Release Versions",
        "SAP R/3 architecture",
        "Type of Servers",
        "SAP Project Types",
        "Modules Introduction",
        "SAP Project Phases",
      ],
    },
    {
      title: "ASAP Methodology ",
      subpoints: [
        "Phases of Project",
        "Project Preparation",
        "BBP (Business Blue Print)",
        "Realization",
        "Final Preparation",
        "Go Live",
      ],
    },
    {
      title: "SAP R/3 Architecture",
      subpoints: [
        "Central System",
        "Distributed Presentation",
        "2-Tier Configuration",
        "3-Tier Configuration",
      ],
    },
    {
      title: "System Landscape & Flow",
      subpoints: [
        "Single System Landscape",
        "Two System Landscape",
        "Three System Landscape",
        "Multi System Landscape",
      ],
    },
    {
      title: "GUI Administration",
      subpoints: ["GUI Installation", "How to added System in SAP GUI"],
    },
    {
      title: "Profile Maintenance & Parameters Setting",
      subpoints: [
        "System Profile",
        "Transport Profile",
        "Client Profile",
        "Authorization Profile",
      ],
    },
    {
      title: "User Administration",
      subpoints: [
        "Creation & Maintaining Single and Mass Users and User Groups",
        "Copying, Deleting, Locking/Unlocking User & Rest Password",
        "Create /Maintaining Roles/Generating Profiles by Using PFCG",
        "Explanation of Terms Object Class, Authorization Object, Authorization Profile",
        "Logon and Password security, Protecting Special Users",
      ],
    },
    {
      title: " Client Administration",
      subpoints: [
        "Client Maintenance",
        "Client Types and default client",
        "Copying Client Within R/3 System (Local)",
        "Copying client between R/3 System (Remote)",
        "Export /Import",
        "Client profiles",
        "Client Deletion",
        "Open & Close Client",
      ],
    },
    {
      title: "Transport Management System",
      subpoints: [
        "Configuring TMS & Checking Transport Directory",
        "Creating Consolidation and Delivery Routes",
        "Transport Request Release",
        "TR Import Dev to QAS to PRD using CHARM and Manually",
        "Copy DATA and COFILE from one server to another using Putty and Windows",
        "Customizing, Workbench & Transport Organizer",
      ],
    },
    {
      title: "Spool Administration",
      subpoints: [
        "Print Related Terminology in OS/SAP Level",
        "Setting Local, Remote & Front -End printing",
        "Printer installation and setup",
        "Checks spool request and Output device",
      ],
    },
    {
      title: "Patch and Kernel Administration",
      subpoints: [
        "Check Product Availability Matric (PAM)",
        "Downloading files from SAP market place.",
        "Apply at OS level and front -End",
        "Troubleshooting Various issues",
      ],
    },
    {
      title: "Background job Management",
      subpoints: [
        "Basis Background Job Concepts",
        "Defining Jobs",
        "Analyzing Jobs",
        "Troubleshooting and analysis Long Running Jobs",
        "Types of Work Process and Use",
      ],
    },
    {
      title: "Distributed R/3 System",
      subpoints: ["Dialog Instance", "Central Instance"],
    },
    {
      title: "SNOTE Concepts",
      subpoints: [
        "Downloading SAP Notes",
        "Manually Applying OSS Notes ON SAP",
      ],
    },
    {
      title: "Database Management",
      subpoints: [
        "Introduction to Oracle DB Using Putty",
        "Introduction to HANA DB",
        "Basic Linux Commands",
      ],
    },
    {
      title: "Basic Imported Concepts",
      subpoints: [
        "Open OSS Connection",
        "S-User ID Creation",
        "Developer key and Object Key Certificate Renewal using STRUST",
        "50 Plus BASIS T-codes Use",
        "Daily All System Monitoring.",
      ],
    },
    {
      title: "Interview preparation",
      subpoints: [
        "Interview question",
        "Profile Building",
        "Mock Interview",
        "Project making",
      ],
    },
  ];

  const faqs = [
    {
      question: "What is SAP BASIS?",
      answer:
        "SAP BASIS stands for Business Application Software Integrated Solution. It acts as the technical foundation that enables SAP applications to function. It includes middleware programs and tools that support the interoperability and portability of SAP applications across systems and databases.",
    },
    {
      question:
        "What are the main responsibilities of a SAP BASIS administrator?",
      answer:
        "An SAP BASIS administrator is responsible for installing and configuring SAP systems, managing user accounts and authorizations, performing system monitoring and tuning, ensuring system security, and managing backups and recovery procedures.",
    },
    {
      question: "What skills are required to become an SAP BASIS consultant?",
      answer:
        "Key skills include knowledge of SAP architecture, proficiency in database management, familiarity with operating systems (such as Unix/Linux and Windows), understanding of networking, and experience with system performance tuning and troubleshooting.",
    },
    {
      question: "What is the difference between SAP BASIS and SAP ABAP?",
      answer:
        "SAP BASIS focuses on the administration and technical aspects of SAP systems, while SAP ABAP (Advanced Business Application Programming) involves coding and developing applications within the SAP environment. BASIS ensures the system runs smoothly, whereas ABAP developers create and maintain the application code.",
    },
    {
      question: "How does SAP BASIS support system security?",
      answer:
        "SAP BASIS supports system security by managing user access, implementing authorization checks, configuring security policies, and ensuring compliance with security standards. It also involves regular audits and monitoring to prevent unauthorized access.",
    },
    {
      question: "What tools are commonly used by SAP BASIS administrators?",
      answer:
        "Common tools include SAP Solution Manager for system monitoring, SAP NetWeaver Administrator for system management, SAP GUI for user interface, and various database management tools depending on the database used (e.g., Oracle, SQL Server, HANA).",
    },
    {
      question:
        "What are the common challenges faced by SAP BASIS administrators?",
      answer:
        "Common challenges include handling system upgrades and migrations, managing system performance and tuning, ensuring data security and compliance, troubleshooting system errors, and keeping up with the latest SAP technologies and best practices.",
    },
  ];
  const whatsData = [
    {
      title: "What is SAP BASIS ?",
      points: [
        "System Support: BASIS underpins SAP applications, ensuring smooth integration and operation.",
        "Administration: Manages SAP system configuration, monitoring, and troubleshooting.",
        "Security: Controls user access and safeguards against unauthorized entry.",
        "Database Management: Oversees database operations, backups, and performance optimization.",
        "IT Accurate authorised training centers in Thane provide SAP Basis training in offline and online mode. Starting end user, consulting, implementation, support with real-time SAP project-based training.",
        "IT background, non-IT background, freshers, and experienced individuals can start their career in SAP irrespective of their background.",
        "IT Accurate is the best training institute in Thane with 100% job opportunities.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //     { Image: rev1, alt: "rev1" },
  //     { Image: rev2, alt: "rev1" },
  //     { Image: rev3, alt: "rev1" },
  //     { Image: rev4, alt: "rev1" },
  //     { Image: rev5, alt: "rev1" },
  // ]
  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  return (
    <div className="devops-page">
      {/* <SideDropdown roadmapData={modulesData} /> */}
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
        <h1>IT Accurate - Best SAP BASIS Training in Thane - Fees, Placements</h1>
          <p>
            IT Accurate provides top-notch SAP Basis training for both freshers
            and experienced candidates in Thane. With expert guidance and a
            100% job placement guarantee, our training covers every technical
            topic in detail, ensuring real-time, hands-on experience.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program not only awards you a
            certificate but also equips you with knowledge equivalent to 2+
            years of industry experience. We value your time and offer SAP Basis
            with S/4 HANA training that can be completed in just 4 months. IT
            Accurate’s SAP training institute in Thane delivers practical,
            job-oriented training, and our SAP Basis with S/4 HANA course is
            designed to provide the equivalent of 7+ years of field experience,
            all within a 4-month timeframe.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4
              HANA)
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2
              months (ECC / S4 HANA)
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> Project 1 month
            </li>
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      {/* <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div> */}
             <StickyNavbar name="SapBasis" />
      <WhatsDevOps whatsData={whatsData} />

      <Roadmap heading="Roadmap to Learn SAP BASIS" roadmapData={roadmapData} />
      <Syllabus heading="SAP BASIS Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP BASIS Training" keyFeatures={keyFeatures} />

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">SAP BASIS CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification will serve as proof that the courses were completed by IT Accurate.
              </li>
              <li>
                The SAP BASIS certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.
              </li>
              <li>
                IT Accurate provides comprehensive guidance for your SAP BASIS global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.
              </li>
            </ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Roadmap heading="SAP modules" roadmapData={modulesData} />

      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}
<CourseOpportunities pageName="SAP_BASIS" />
      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP ABAP Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <MarqueeLogos />
    </div>
  );
};

export default SAPBASIS;
