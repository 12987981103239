import React, { useState, useEffect } from 'react';
import '../css/Batches.css';
import { useModal } from "../context/ModelContext";
import DialogContact from "./DialogContact";
import Review from "../assets/Review Poster.png";

const Batches = () => {
  const [activeTab, setActiveTab] = useState('online');
  const { openModal } = useModal();
  const [currentBatches, setCurrentBatches] = useState([]);
  const [formStatus, setFormStatus] = useState(""); // For showing thank-you message
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Function to calculate valid batch dates
  const getBatchDates = () => {
    const today = new Date();
    const predefinedDates = [1, 5, 12, 19, 26]; // Predefined dates

    const nextBatchDates = [];
    for (let i = 0; i < 4; i++) {
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      // Find the next valid date within the current or upcoming months
      let validDate = predefinedDates.find(date => date > today.getDate());
      if (!validDate) {
        validDate = predefinedDates[0]; // Reset to the first date of the next month
        today.setMonth(currentMonth + 1);
        today.setDate(1);
      } else {
        today.setDate(validDate);
      }

      // Add the valid date to the list
      nextBatchDates.push(
        new Date(today.getFullYear(), today.getMonth(), validDate).toLocaleDateString(undefined, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
      );

      // Move to the next month if required
      if (validDate === predefinedDates[0]) {
        today.setMonth(currentMonth + 1);
        today.setDate(1);
      }
    }

    return nextBatchDates;
  };

  useEffect(() => {
    setCurrentBatches(getBatchDates());
  }, []);

  const handleEnrollClick = () => {
    openModal(<DialogContact />);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",  //shivanihiware mail
          subject: "New Contact Form Submission",
          from_name: "ITAccurate",
          ...formData,
        }),
      });

      if (response.ok) {
        setFormStatus("Thank you for your submission!");
        setFormData({ name: "", email: "", message: "" }); // Clear the form
      } else {
        setFormStatus("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setFormStatus("Something went wrong. Please check your connection.");
    }
  };

  return (
    <div className="batches-container">
      {/* Left Section */}
      <div className="left-content">
        <h2>Upcoming Batches</h2>
        <div className="tabs">
          <button
            className={activeTab === 'online' ? 'active' : ''}
            onClick={() => setActiveTab('online')}
          >
            Online Classroom
          </button>
          <button
            className={activeTab === 'corporate' ? 'active' : ''}
            onClick={() => setActiveTab('corporate')}
          >
            Offline Training
          </button>
        </div>

        <div className="content">
          {activeTab === 'online' ? (
            <div className="schedule">
              {currentBatches.map((date, index) => (
                <div className="batch" key={index}>
                  <span>{date}</span>
                  <span>Mon-Sat</span>
                  <span>Flexible Timing</span>
                  <span>Weekdays Regular</span>
                  <span>1hr - 1:30hr</span>
                  <button onClick={handleEnrollClick}>Course Fees</button>
                </div>
              ))}
            </div>
          ) : (
            <form className="corporate-form" onSubmit={handleSubmit}>
              <h3>Offline Training Inquiry</h3>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </label>
              <label>
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write your message"
                  required
                ></textarea>
              </label>
              <button type="submit">Submit</button>
            </form>
          )}
          {formStatus && <p className="form-status">{formStatus}</p>}
        </div>
      </div>

      {/* Right Section */}
      <div className="right-content">
        <img
          src={Review}
          alt="Review Poster"
          className="review-poster"
        />
      </div>
    </div>
  );
};

export default Batches;
