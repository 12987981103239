import React, { useEffect, useRef } from 'react';
import '../../css/DevOps.css';
import Alumni from '../../components/MarqueeLogos'
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from '../../components/Roadmap';
import WhatsDevOps from '../../components/WhatsDevOps'
import Syllabus from '../../components/Syllabus';
import KeyFeatures from '../../components/KeyFeatures';
import SalaryHikeSection from '../../components/SalaryHike';
import Faq from '../../components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../../components/ContactForm';
import Learn from '../../components/Learn';
import Marq from '../../components/CustomMarquee';
import JobPreparation from '../../components/JobPreparation';
import CourseOpportunities from '../../components/CourseOpportunities'
import BatchesPage from "../../components/Batches";
import StickyNavbar from "../../components/StickyNavbar";
// import dev1 from '../../assets/Projects/Salesforce/1.png';
// import dev2 from '../../assets/Projects/Salesforce/2.png';
// import dev3 from '../../assets/Projects/Salesforce/3.png';
// import dev4 from '../../assets/Projects/Salesforce/4.png';
// import dev5 from '../../assets/Projects/Salesforce/5.png';
// import dev6 from '../../assets/Projects/Salesforce/6.png';
// import dev7 from '../../assets/Projects/Salesforce/7.png';
// import dev8 from '../../assets/Projects/Salesforce/8.png';
// import dev9 from '../../assets/Projects/Salesforce/9.png';
// import dev10 from '../../assets/Projects/Salesforce/10.png';

// import rev1 from '../../assets/Reviews/Salesforce/sf_rev1.png';
// import rev2 from '../../assets/Reviews/Salesforce/sf_rev2.png';
// import rev3 from '../../assets/Reviews/Salesforce/sf_rev3.png';
// import rev4 from '../../assets/Reviews/Salesforce/sf_rev4.png';
// import rev5 from '../../assets/Reviews/Salesforce/sf_rev5.png';
// import RegularSlider from '../../Components/RegularSlider';


const SalesforceLWC = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'syzh1IxEaak', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'syzh1IxEaak', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'Salesforce LWC Development',
        'What is Salesforce LWC?',
        'Salesforce LWC Careers',
        'Salesforce LWC course',
        'Salesforce LWC certification',
        'Salesforce LWC job in india',
        'Salesforce LWC job in Thane'
      ]
    },
    {
      title: 'Understanding Salesforce Fundamentals',
      points: [
        'Salesforce login',
        'Salesforce trailhead login',
        'All the topics of salesforce admin are covered in detail',
      ]
    },
    {
      title: 'Salesforce Development',
      points: [
        'Salesforce developer login',
        'Basic of C Language',
        'APEX Programming',
        'All the topics of salesforce development are covered in detail',
      ]
    },
    {
      title: 'Salesforce LWC and Integration',
      points: [
        'What is LWC?',
        'Basic HTML',
        'JAVA SCRIPT',
        'All the topics of salesforce Lwc and Integration are covered in detail.'
      ]
    },
    {
      title: 'Salesforce Einstein Bots',
      points: [
        'Building a NLP-Powered chatbot',
        'Dialog flows using no-coding configuration',
        'Out of the box functions using APX class',
        'Deploy your Bot to website'
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keyword',
        'Show impact',
        'Include soft skill',
        'Be unique'
      ]
    },
    {
      title: 'Interview Practice',
      points: [
        'How to do face to face online & offline interview',
        'One of one interview sessions',
        'Group discussion',
        'Grooming session',
        'Research the company and follow ups'
      ]
    },
    {
      title: 'JOB opportunities',
      points: [
        'Preapare for your JOB search',
        'Ready Walkin in India',
        'Tie Up refernces',
        'GET PLACED',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction To HTML',
      subpoints: [
        'Basic Of HTML.',
        'HTML Tags',
        'HTML Attributes',
        'Inline CSS',
      ],
    },
    {
      title: 'Introduction To JavaScript',
      subpoints: [
        'DataTypes In Java Script',
        'Interaction with alert, prompt and confirm',
        'Variables In JS',
        'Function and Methods In JS',
        'Object In JS',
      ],
    },
    {
      title: 'Introduction To Lightening Web Components',
      subpoints: [
        'Base Componenets In LWC',
        'Lightning-Card',
        'Lightning-Input',
        'Lightning-Button',
        'Lightning Record Edit Form',
        'Lightning View Form',
        'Lightning Input Form',
        'Lightning Output Form',
      ],
    },
    {
      title: 'Decorators in LWC',
      subpoints: [
        'Track',
        'API',
        'WIRE',
      ],
    },
    {
      title: 'Life Cycle Hooks In LWC',
      subpoints: [
        'Constructors',
        'Connected Call Back',
        'Disconnected Call Back',
        'Rendered Call Back',
        'Error Call Back',
      ],
    },
    {
      title: 'Compositions In LWC',
      subpoints: [
        'Parent to Child Communication',
        'Child to Parent Communication',
        'Getter and Setter Methods',
      ],
    },
    {
      title: 'Introduction To Lightning Design System',
      subpoints: [
        'How to Used lds in LWC',
        'LDS Components',
      ],
    },
    {
      title: 'Introduction To Integration',
      subpoints: [
        'Exeptions',
        'Exception Statements',
        'System-Defined Exception',
        'Catching Different Exception Types',
        'Try Catch and Finally Methods',
      ],
    },
    {
      title: 'APEX Testing Essentials',
      subpoints: [
        'Write and run APEX Tests',
        'Create Tests Data',
        'Running Test Units',
        'Testing Best Practices',
        'Code Coverage',
        'Test Classes Methods',
      ],
    },

  ];

  const keyFeatures = [
    { text: "34+ Hrs Instructor-Led Training", icon: "fa-chalkboard-teacher" },
    { text: "Certificate Guidance", icon: "fa-certificate" },
    { text: "Mentor Support", icon: "fa-user-friends" },
    { text: "Resume Editing", icon: "fa-file-alt" },
    { text: "10+ Hrs Project & Exercises", icon: "fa-tasks" },
    { text: "100% Job Oriented Training", icon: "fa-graduation-cap" },
    { text: "56+ Hrs Self-Paced Videos", icon: "fa-video" },
    { text: "Job Assistance", icon: "fa-briefcase" },
    { text: "Free Demo Class Available", icon: "fa-chalkboard" },
    { text: "Affordable Fee Structure", icon: "fa-money-bill-wave" },
    { text: "Flexible Schedule", icon: "fa-calendar-alt" },
    { text: "Completed 420+ Batches", icon: "fa-users" }
  ];

  const faqs = [
    {
      question: "What is Salesforce LWC?",
      answer: "LWC is a modern UI framework within Salesforce for building responsive web applications using JavaScript and HTML.",
    },
    {
      question: "How do I start learning Salesforce LWC?",
      answer: "Begin by understanding LWC basics like component structure, JavaScript controllers, and HTML templates, then practice building simple components.",
    },
    {
      question: "What is Integration in Salesforce?",
      answer: "Integration in Salesforce refers to connecting with external systems such as databases and third-party applications for data exchange and synchronization.",
    },
    {
      question: "What are Salesforce APIs used for Integration?",
      answer: "Salesforce APIs like REST and SOAP are used for seamless integration with external systems, enabling data retrieval, updates, and real-time communication.",
    },
    {
      question: "How do I integrate Salesforce with external systems?",
      answer: "Integration with external systems involves using Salesforce APIs, setting up authentication, mapping data fields, and implementing integration patterns.",
    },
    {
      question: "What are the benefits of using Salesforce LWC for UI development?",
      answer: "LWC offers benefits like improved performance, code reusability, responsive design, and seamless integration with Salesforce features.",
    },
    {
      question: "What integration patterns are commonly used in Salesforce?",
      answer: "Common integration patterns in Salesforce include point-to-point, publish-subscribe, batch data synchronization, and real-time event-driven integration.",
    },
    {
      question: "How can I ensure data security in Salesforce Integration?",
      answer: "Ensure data security in Salesforce Integration by implementing secure authentication methods, setting up access controls, and encrypting sensitive data during transmission.",
    },
  ];

  const whatsData = [
    {
      title: 'What is Salesforce LWC & Integration?',
      points: [
        'Modern UI Framework: LWC is a modern UI framework within Salesforce, enabling fast development and enhanced user experiences.',
        'External System Connectivity: Integration with external systems, such as databases and third-party applications, ensures seamless data exchange and synchronization.',
        'Improved Performance: LWC’s use of modern web standards and APIs like REST and SOAP enhances performance and allows real-time data updates across platforms.',
        'IT background, non-IT background, freshers, and experienced individuals can start their career in Salesforce development, regardless of their prior background.',
        'Begin by understanding the basics of the platform, get training with IT Accurate, and join user groups, communities, and placement-oriented Salesforce LWC training to gain hands-on experience.',
        'Becoming a Salesforce pro is achievable through unwavering dedication, hard work, and commitment.'
      ]
    }
  ];


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //   { Image: dev1, alt: "Award 1" },
  //   { Image: dev2, alt: "Award 2" },
  //   { Image: dev3, alt: "Award 3" },
  //   { Image: dev4, alt: "Award 4" },
  //   { Image: dev5, alt: "Award 5" },
  //   { Image: dev6, alt: "Award 6" },
  //   { Image: dev7, alt: "Award 7" },
  //   { Image: dev8, alt: "Award 8" },
  //   { Image: dev9, alt: "Award 7" },
  //   { Image: dev10, alt: "Award 8" },
  // ];

  // const reviewList = [
  //   { Image: rev1, alt: "rev1" },
  //   { Image: rev2, alt: "rev1" },
  //   { Image: rev3, alt: "rev1" },
  //   { Image: rev4, alt: "rev1" },
  //   { Image: rev5, alt: "rev1" },
  // ]

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>IT Accurate - Best Salesforce LWC & Integration Training in Thane - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6367 Learners</span>
          </div>
          <p>
            Salesforce Training by IT Accurate offers you the Best Salesforce Training in Thane for Freshers and Experienced candidates in Thane, with Expert Guidance and 100% Guaranteed Placement Assistance. Our Training Course covers every technical topic in detail, and students are left with Real-Time Hands-On Experience.
          </p>
          <br />
          <p>
            Our practical, Job-Oriented Training Program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of Field Experience. We value your time as much as ours. Hence, we intend to train you in Salesforce in just 1 month.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><i className="fas fa-file-alt"></i> *Resume preparation</li>
            <li><i className="fas fa-chalkboard-teacher"></i> *Interview practice</li>
            <li><i className="fas fa-briefcase"></i> *6-months internship</li>
            <li><i className="fas fa-check-circle"></i> *100% job opportunities guaranteed program</li>
          </ul>
        </div>

        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <StickyNavbar name="SalesforceLWC" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="salesLwcIntegration" />

      <Roadmap heading="Roadmap to learn Salesforce LWC & INTEGRATION" roadmapData={roadmapData} />
      <JobPreparation />
      <KeyFeatures heading="Salesforce LWC & INTEGRATION" keyFeatures={keyFeatures} />
      <Syllabus heading="Salesforce LWC & INTEGRATION Syllabus" Syllabus={syllabus} />




      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for Salesforce LWC Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the Salesforce LWC & INTEGRATION Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Salesforce LWC</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
          </tbody>
        </table>
      </div></div> */}
      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">Salesforce LWC Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>IT Accurate training certification will serve as proof that the courses were completed by IT Accurate.</li>
              <li>The Salesforce LWC certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>IT Accurate provides comprehensive guidance for your Salesforce LWC global certification, ensuring a 100% passing guarantee in examinations.</li>
              <li>Salesforce LWC Platform Development Certification and various other global exams.</li>
            </ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Salesforce LWC & INTEGRATION</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      <
 /div> */}
      <CourseOpportunities pageName="SalesforceLWC" />
      <Marq />
      <Faq Faqs={faqs} />
      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}
      <Alumni />

    </div>
  );
};

export default SalesforceLWC;