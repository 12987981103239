import React, { useEffect, useRef } from 'react';
import '../../css/DevOps.css';
import Alumni from '../../components/MarqueeLogos'
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from '../../components/Roadmap';
import WhatsDevOps from '../../components/WhatsDevOps'
import Syllabus from '../../components/Syllabus';
import KeyFeatures from '../../components/KeyFeatures';
import Faq from '../../components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../../components/ContactForm';
import CourseOpportunities from '../../components/CourseOpportunities'
import StickyNavbar from "../../components/StickyNavbar";
const SAPPP = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);

  const modulesData = [
    {
      title: "SAP",
      points: [
        "IT Accurate Training in Thane offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/best-sap-training",
    },
    {
      title: "SAP BASIS",
      points: [
        "IT Accurate Training in Thane offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-Thane",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. IT Accurate Institute in Thane offers comprehensive training in SAP MM for effective utilization.",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by IT Accurate Institute Thane, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations.",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. IT Accurate Institute in Thane is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. IT Accurate Institute in Thane likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: " SAP SuccessFactors",
      points: [
        "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
      ],
      link: "/sap-successfactors",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. IT Accurate Institute Thane is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        ' What is SAP?',
        ' What is SAP PP?',
        ' What is SAP HANA?',
        ' SAP course',
        ' SAP certification',
        ' SAP jobs in india',
        ' SAP jobs in Thane',
      ]
    },
    {
      title: ' System Landscape Design',
      points: [
        'Design the SAP landscape, considering integration with other modules (e.g., SAP MM, SAP SD).',
        'Plan system architecture, hardware requirements, and scalability.',
      ]
    },
    {
      title: 'Data Migration and Cleansing',
      points: [
        'Assess existing data quality and cleanse data for migration.',
        'Develop a data migration strategy and execute the migration plan.',
      ]
    },
    {
      title: 'Customization and Configuration',
      points: [
        'Customize SAP PP to align with specific organizational requirements.',
        'Configure master data such as work centers, routings, and production versions.',
      ]
    },
    {
      title: 'Prototype and Testing',
      points: [
        'Develop a prototype of the SAP PP system for initial testing and validation.',
        'Conduct unit testing, integration testing, and user acceptance testing (UAT).',
      ]
    },
    {
      title: ' Deployment and Go-Live',
      points: [
        'Plan and execute the deployment of SAP PP in a controlled environment.',
        'Monitor and address any issues during the initial phase of operation.',
      ]
    },
    {
      title: 'Performance Metrics and KPIs',
      points: [
        'Define and monitor key performance indicators (KPIs) for production planning.',
        'Use metrics to evaluate the success and efficiency of the SAP PP implementation.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to ERP',
      subpoints: [
        'ERP Packages',
        'Introduction to SAP',
        'SAP Logistics Cycle SD/MM/PP',
        'PP Flow and Procurement Types',
      ],
    },
    {
      title: 'SAP Navigation',
      subpoints: [

      ],
    },
    {
      title: 'Organizational structure of an Enterprise in the SAP R/3 System',
      subpoints: [
        'Company',
        'Company code,',
        'Controlling Area,',
        'Plant, Storage Location,',
        'Purchase Organization,',
        'Purchasing group,',
        'Sales Organization,',
        'Distribution Channel,',
        'Division,',
        'Sales Office,',
        'Sales Group,',
        'Shipping Point Etc…',
        'Controlling Settings (Cost Center and Cost Element Etc…)',
      ],
    },
    {
      title: 'Master Data',
      subpoints: [
        'Material Master',
        'Bill of Material – BOM',
        'Work Center',
        'Routing – Different types of Routing',
        'Production Version',
      ],
    },
    {
      title: 'Production Resources/Tools – PRT',
      subpoints: [
        'PRT settings',
        'Master Data Creation for PRT Tools',
        'Assigning of PRT Tools in Routing',
      ],
    },
    {
      title: 'Sales and Operational Planning – SOP',
      subpoints: [
        'Creation of Product Groups',
        'Creation SOP Plan',
        'Copy and Change SOP Plan',
        'Transfer of Plan to Demand Management',
        'Display Planned Independent Requirements',
      ],
    },
    {
      title: 'Independent Requirement Planning',
      subpoints: [
        'Demand Planning',
        'Creation of PIR (Planned independent requirements)',
        'Assigning of PRT Tools in Routing',
      ],
    },
    {
      title: 'Sales and Operational Planning – SOP',
      subpoints: [
        'Creation of Product Groups',
        'Creation SOP Plan',
        'Copy and Change SOP Plan',
        'Transfer of Plan to Demand Management',
        'Display Planned Independent Requirements',

      ],
    },
    {
      title: 'Independent Requirement Planning',
      subpoints: [
        'Demand Planning',
        'Creation of PIR (Planned independent requirements)',
      ],
    },
    {
      title: 'MRP – Planning',
      subpoints: [
        'Master Data requirements in MRP',
        'Carrying MRP Procedure MRP, MPS, CBP',
        'Reorder Point Planning – Automatic and Manual',
        'Forecast Based Planning',
        'Time Phased Planning',
        'Independent requirement Planning',
        'MPS Planning',
        'Difference between MRP Vs MPS',
        'Master Production Scheduling planning run (MPS RUN)',
        'MRP Run In Background for all network Plants',


      ],
    },
    {
      title: 'Conversions and Processing Orders',
      subpoints: [
        'Processing Planned ordersy',
        'Planned orders conversion to Production Orders,',
        'Purchase Requisitions,',
        'Process Orders',
        'Production Orders Confirmations',
        'Production Orders Settlement',
        'Technically Complete Production Orders – TECO',
        'Collective conversion of Planned Orders into Production Orders',
        'Collective conversion of Planned Orders into Purchase Requisitions',
        'Collective Release of Production Orders',
        'Collective Confirmation of Production Order',
      ],
    },
    {
      title: 'Goods Movement in PP',
      subpoints: [
        'Goods Receipt wref to Production Order',
        'Goods Receipt w/o Production Order',
        'Goods Issue wref to Production Order',
        'Automatic Creation of GR and Release of the Production Order',
        'Creation of GI wref to Reservation',
      ],
    },
    {
      title: 'Serial Number Assignment in PP',
      subpoints: [
        'Define Serial Number Profile',
        'Maintaining Serial Number Profile in the Material Master',
        'Manual and Automatic creation of Serial Numbers in Production Orders',
        'List display of Serial Numbers',
      ],
    },
    {
      title: 'Batch Management',
      subpoints: [
        'Characteristics, Class and Sort Sequence',
        'SLED In Batch in PP',
        'Condition Tables',
        'Access Sequence',
        'Strategy Types',
        'PP Batch Search Procedure',
        'Maintain Batch Search Procedure for the Order Type',
        'Production orders creation using Batch Automatic Batch Display at Production order creation',
        'Production order Release',

      ],
    },
    {
      title: 'Strategy Group',
      subpoints: [
        'Define Strategy',
        'Define Strategy Group',
        'Assign MRP Group to Strategy Group',
        'Process Flow for each Strategy Group',
        'Detailed Process Flow for each Strategy Group',
      ],
    },
    {
      title: 'Discrete Manufacturing Process',
      subpoints: [
        'Make To Order Cycle – MTO Process',
        'Make To Stock Cycle – MTS Process',
      ],
    },
    {
      title: 'Variant Configuration in PP',
      subpoints: [
        'Characteristics',
        'Class',
        'Creation of Configurable Material Master along with Classification',
        'Sales BOM for the Configurable Material',
        'Assignment of Object Dependencies for the Characteristics',
        'Maintaining Condition records',
        'Final Execution of the Variant Configuration',
      ],
    },
    {
      title: 'Availability in MRP in PP',
      subpoints: [
        'Define Requirement Classes and Requirement Types',
        'Define Checking Rule and Availability Check',
      ],
    },
    {
      title: 'Repetitive Manufacturing Process',
      subpoints: [
        'Master Data Maintenance',
        'Production versions',
        'REM Profile',
        'Backflushing',
        'REM Back flushing',
      ],
    }
  ];

  const keyFeatures = [
    { text: "124+ Hours course duration", icon: "fa-clock" },
    { text: "100% Job oriented Training", icon: "fa-briefcase" },
    { text: "Industry expert faculties", icon: "fa-chalkboard-teacher" },
    { text: "Free demo class available", icon: "fa-chalkboard" },
    { text: "Completed 200+ Batches", icon: "fa-users" },
    { text: "Certification guidance", icon: "fa-certificate" },
  ];

  const faqs = [
    {
      question: "What is SAP PP?",
      answer: "SAP PP (Production Planning) is a module in SAP ERP that helps organizations plan and manage their manufacturing processes.",
    },
    {
      question: "What are the key components of SAP PP?",
      answer: "SAP PP includes components like Master Data, Bill of Materials (BOM), Work Centers, and Production Orders.",
    },
    {
      question: "How to create a Production Order in SAP PP?",
      answer: "Use transaction code CO01 to create a Production Order. Enter details like material, quantity, and scheduling information.",
    },
    {
      question: "What is BOM in SAP PP?",
      answer: "BOM (Bill of Materials) is a list of components or materials required to produce a finished product in SAP PP.",
    },
    {
      question: "How to check material availability in SAP PP?",
      answer: "Transaction code MD04 allows you to check material availability. It displays the stock situation and planned requirements for a material.",
    },
    {
      question: "What is Capacity Planning in SAP PP?",
      answer: "Capacity Planning in SAP PP involves determining the production capacity required and available for manufacturing orders.",
    },
    {
      question: "How to perform MRP (Material Requirements Planning) in SAP PP?",
      answer: "Transaction code MD01/MD02 is used for MRP in SAP PP. It helps in calculating material requirements based on demand and stock levels.",
    },
    {
      question: "What is the difference between Discrete and Process Manufacturing in SAP PP?",
      answer: "Discrete Manufacturing is for products with distinct, separate units, while Process Manufacturing is for continuous production, like in the chemical industry.",
    },
    {
      question: "How to reschedule a Production Order in SAP PP?",
      answer: "Use transaction code CO02 to access the Production Order and adjust the dates as needed for rescheduling.",
    },
    {
      question: "What is SAP PP-PI (Production Planning for Process Industries)?",
      answer: "SAP PP-PI is a sub-module of SAP PP designed specifically for process industries, providing functionalities like batch management and recipe management.",
    }
  ];

  const whatsData = [
    {
      title: 'What is SAP PP?',
      points: [
        'SAP PP (Production Planning) is a module within SAP ERP software.',
        'It facilitates efficient planning and execution of production processes.',
        'Integrates various business processes to optimize production workflows.',
        'Manages master data related to production, such as bills of materials and work centers.',
        'Enables capacity planning to ensure optimal resource utilization.',
        'Supports demand-driven production planning for timely order fulfillment.',
        'Provides real-time monitoring of production activities.',
        'Allows for the creation and management of production orders.',
        'IT Accurate training provides SAP PP training in offline and online mode. Starting end user, consulting, implementation, support with real-time SAP project-based training.',
        'IT background, non-IT background, freshers, and experienced individuals can start their career in SAP irrespective of their background.',
        'IT Accurate is the best SAP PP training institute in Thane with 100% job opportunities.',
      ]
    }
  ];


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>IT Accurate - Best SAP PP Training in Thane - Fees, Placements</h1>
          <p>
            IT Accurate provides top-notch SAP PP training in Thane for both freshers and experienced professionals, offering expert guidance and a 100% placement assistance guarantee. Our SAP PP course covers all the technical aspects in depth, ensuring that students gain hands-on experience in real-world scenarios.
          </p>
          <p>
            Our practical, job-focused training program not only awards you a certificate but also equips you with knowledge comparable to over 2 years of industry experience. We understand the value of your time, which is why we’ve designed the SAP PP training to be completed in just 4 months.
          </p>


          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
          </ul>
        </div>
        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>


      {/* 
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div> */}
      <StickyNavbar name="SapPP" />

      <Roadmap heading="Roadmap to learn SAP PP" roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP PP Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP PP Training" keyFeatures={keyFeatures} />

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">SAP PP CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>IT Accurate training certification will serve as proof that the courses were completed by IT Accurate.</li>
              <li>The SAP S/4HANA certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>IT Accurate provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />
      <CourseOpportunities pageName="SAP_PP" />
      <Faq Faqs={faqs} />
      <Alumni />
    </div>
  );
};

export default SAPPP;