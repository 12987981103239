import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Learn from '../components/Learn';
import Marq from '../components/CustomMarquee';
import JobPreparation from '../components/JobPreparation';
import BatchesPage from "../components/Batches";
import SalaryHikeSection from '../components/SalaryHike';
import StickyNavbar from "../components/StickyNavbar";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";
import CourseOpportunities from '../components/CourseOpportunities'

const AIML = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 't0_uO42Cr_w', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // /event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 't0_uO42Cr_w', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is AI & ML?',
        'AI & ML course',
        'AI & ML certification',
        'AI & ML jobs in india',
        'AI & ML jobs in Thane'
      ]
    },
    {
      title: 'Foundational Skills',
      points: [
        'Gain expertise in obtaining and collecting data from various sources.',
        'Master techniques for cleaning and preprocessing data to ensure its quality and reliability.',
        'Explore data storage solutions and databases.',
      ]
    },
    {
      title: ' Data Acquisition and Cleaning',
      points: [
        'Gain expertise in obtaining and collecting data from various sources.',
        'Master techniques for cleaning and preprocessing data to ensure its quality and reliability.',
        'Explore data storage solutions and databases.',
      ]
    },
    {
      title: 'Exploratory Data Analysis (EDA)',
      points: [
        'Learn exploratory data analysis techniques to understand the structure and patterns within the data.',
        'Use data visualization tools like Matplotlib and Seaborn to create meaningful plots.',
        'Develop the ability to ask relevant questions about the data and derive insights.',
      ]
    },
    {
      title: 'Machine Learning Fundamentals',
      points: [
        'Understand the basics of machine learning algorithms and their applications.',
        'Learn how to train and evaluate models using popular frameworks like scikit-learn.',
        'Explore supervised and unsupervised learning techniques.',
      ]
    },
    {
      title: ' Advanced Machine Learning and Deep Learning',
      points: [
        'Dive into more advanced machine learning topics such as ensemble methods and hyperparameter tuning.',
        'Familiarize yourself with deep learning concepts and frameworks like TensorFlow or PyTorch.',
        'Experiment with neural networks and understand their architectures.',
      ]
    },
    {
      title: 'Model Deployment and Communication',
      points: [
        'Learn how to deploy machine learning models to production environments.',
        'Develop skills in model interpretation and explainability.',
        'Practice effective communication of data-driven insights to both technical and non-technical stakeholders.',
      ]
    },

    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare resume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skill',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Module 1',
      subpoints: [
        'Introduction to Python',
        'Python Basics',
        'Python Functions and Packages',
        'Working with Data Structures, Arrays',
        'Vectors & Data Frames',
        'Google colab notebook',
        'Pandas, NumPy, Matplotlib, Seaborn',
      ],
    },
    {
      title: 'Module 2 (1 and half weeks)',
      subpoints: [
        'Applied Statistics',
        'Descriptive Statistics',
        'Probability & Conditional Probability',
        'Hypothesis Testing',
        'Inferential Statistics',
        'Probability Distributions',
      ],
    },
    {
      title: 'Self paced module',
      subpoints: [
        'EDA and Data Processing',
        'Data Types',
        'Dispersion & Skewness',
        'Uni & Multivariate Analysis',
        'Data Imputation',
        'Identifying and Normalizing Outlier',
      ],
    },
    {
      title: 'Machine learning',
      subpoints: [
        'Supervised learning',
        'Linear Regression',
        'Multiple Variable Linear Regression',
        'Logistic Regression',
        'Naive Bayes Classifiers',
        'k-NN Classification',
        'Support Vector Machines',
      ],
    },
    {
      title: 'Unsupervised Learning',
      subpoints: [
        'Unsupervised Learning',
        'K-means Clustering',
        'Hierarchical Clustering',
        'Dimension Reduction-PCA',
      ],
    },
    {
      title: 'Ensemble Techniques',
      subpoints: [
        'Decision Trees',
        'Bagging',
        'Random Forests',
        'Boosting',
      ],
    },
    {
      title: 'Featurisation, Model Selection & Tuning',
      subpoints: [
        'Feature Engineering',
        'Model Selection and Tuning',
        'Model Performance Measures',
        'Regularising Linear Models',
        'MI Pipeline',
        'Bootstrap Sampling',
        'Grid Search Cv',
        'Randomized Search Cv',
        'K Fold Cross-validation',
      ],
    },
    {
      title: 'Neural Networks and Deep Learning',
      subpoints: [
        'Introduction to Perceptron & Neural Networks',
        'Activation and Loss functions',
        'Gradient Descent',
        'Batch Normalization',
        'TensorFlow & Keras for Neural Networks',
        'Hyper Parameter Tuning',
      ],
    },
    {
      title: 'Computer Vision',
      subpoints: [
        'Introduction to Convolutional Neural Networks',
        'Introduction to Images',
        'Convolution, Pooling, Padding its Mechanisms',
        'Forward Propagation & Backpropagation for CNNs',
        'CNN architectures like AlexNet, VGGNet,InceptionNet & ResNet',
        'Transfer Learning',
        'Object Detection',
        'YOLO, R-CNN, SSD',
        'Semantic Segmentation',
        'U-Net',
        'Face Recognition using Siamese Networks',
        'Instance Segmentation',
      ],
    },
    {
      title: 'NLP (Natural Language Processing)',
      subpoints: [
        'Introduction to NLP',
        'Stop Words.',
        'Tokenization',
        'Stemming and Lemmatization',
        'Bag of Words Model',
        'Word Vectorizer',
        'TF-IDF',
        'POS Tagging',
        'Named Entity Recognition',
        'Introduction to Sequential data',
        'RNNs and its Mechanisms',
        'Vanishing & Exploding gradients in RNNs',
        'LSTMs – Long short-term memory',
        'GRUs – Gated Recurrent Unit',
        'LSTMs Applications',
        'Time Series Analysis',
        'LSTMs with Attention Mechanism',
        'Neural Machine Translation',
        'Advanced Language Models:',
        'Transformers, BERT, XLNet',
      ],
    },
    {
      title: 'Introduction to SQL',
      subpoints: [
        'Introduction To DBMS',
        'ER Diagram',
        'Schema Design',
        'Key Constraints and Basics of Normalization.',
        'Joins',
        'Subqueries Involving Joins and Aggregations',
        'Sorting',
        'Independent Subqueries',
        'Correlated Subqueries',
        'Analytic Functions.',
        'Set Operations',
        'Grouping and Filtering',
      ],
    },
    {
      title: 'Power BI',
      subpoints: [
        'Introduction of Power BI and its component',
        'Data preparation and transformation using Power Query',
        'Creating and customizing visualization',
        'Building interactive report and dashboard',
        'Power BI DAX(Data Analysis Expression)',
        'Integration of Power BI with database',
      ],
    },
    {
      title: 'ChatGPT and OpenAl',
      subpoints: [
        'Overview of ChatGPT and OpenAl',
        'Timeline of NLP and Generative Al',
        'Frameworks for Understanding ChatGPTand Generative Al',
        'Implications for Work, Business, andEducation',
        'Output Modalities and Limitations',
        'Business Roles to Leverage ChatGPT',
        'Prompt Engineering for Fine-TuningOutputs',
        'Practical Demonstration and BonusSection on RLHF',
        'Introduction to Generative Al',
        'Al vs ML vs DL vs GenAl',
        'Supervised vs Unsupervised Learning.',
        'Discriminative vs Generative Al',
        'A Brief Timeline of GenAl',
        'Basics of Generative Models',
        'Large Language Models',
        'Word Vectors',
        'ChatGPT: The Development Stack',
        'Attention Mechanism',
        'Business Applications of ML, DL and GenAl',
        'Hands-on Bing Images and ChatGPT',
      ],
    }
  ];

  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  const faqs = [
    {
      question: "What is Data science?",
      answer: "Data science is a multidisciplinary field that uses scientific methods, processes, algorithms, and systems to extract insights and knowledge from structured and unstructured data.",
    },
    {
      question: "How to become a data scientist?",
      answer: "To become a data scientist, acquire a strong foundation in statistics, programming (e.g., Python or R), and machine learning. Gain practical experience through projects and consider obtaining relevant education or certifications.",
    },
    {
      question: "What are the key skills for a data scientist?",
      answer: "Key skills include programming, statistical analysis, machine learning, data visualization, domain knowledge, and communication skills.",
    },
    {
      question: "What programming languages are used in data science?",
      answer: "Python and R are widely used programming languages in data science.",
    },
    {
      question: "What is the difference between machine learning and deep learning?",
      answer: "Machine learning is a broader concept involving algorithms that can learn patterns from data, while deep learning is a subset of machine learning using neural networks with many layers.",
    },
    {
      question: "How to clean and preprocess data for analysis?",
      answer: "Clean and preprocess data by handling missing values, removing duplicates, scaling features, and transforming variables as needed.",
    },
    {
      question: "What is the CRISP-DM model in data science?",
      answer: "CRISP-DM (Cross-Industry Standard Process for Data Mining) is a extensively used version that outlines the steps in the records mining approach, which encompass commercial organization knowledge, records know-how, facts training, modeling, evaluation, and deployment.",
    },
    {
      question: "What are some popular machine learning algorithms?",
      answer: "Popular machine learning algorithms include linear regression, decision trees, random forests, support vector machines, k-nearest neighbors, and neural networks.",
    }
  ];

  const whatsData = [
    {
      title: 'What is Data Science?',
      points: [
        'Data science is a field that combines domain expertise, programming skills, and knowledge of mathematics and statistics to extract valuable insights from data.',
        'Techniques used in data science include machine learning, visualization, pattern recognition, probability modeling, data engineering, signal processing, and more.',
        'Data science is one of the most in-demand jobs of the 21st century. Many companies now rely on data science to make informed decisions and develop actionable plans for the future.',
        'IT Accurate offers data science training in both offline and online modes, covering everything from end-user to consulting and implementation, with real-time, project-based training.'
      ]
    }
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
        <h1>IT Accurate - Best Data Science Training in Thane - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">7489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">7967 Learners</span>
          </div>
          <p>
            IT Accurate offers the best Data Science training in Thane, designed for both freshers and experienced candidates. With expert instructors and 100% job placement assistance, our course covers all the essential topics in detail, providing you with hands-on, real-world experience.
          </p>
          <br />
          <p>
            Our job-oriented training program not only awards you a certificate but also equips you with knowledge equivalent to 2+ years of industry experience. We understand the value of your time, which is why we've tailored this program to be completed in just 7 months.
          </p>

          <h3>The course covers a wide range of topics, including:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> ChatGPT</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Generative AI</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> LLM (Large Language Models)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Computer Vision</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview Preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Generative AI</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Case Studies</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Multiple Projects</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Industry-level Projects</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% Job Guarantee Program</li>
          </ul>
        </div>

        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <StickyNavbar name="Data Science" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="dataScience" />
      <Roadmap heading="Roadmap to Learn Data Science" roadmapData={roadmapData} />
      <JobPreparation />
      <KeyFeatures heading="Data Science Training" keyFeatures={keyFeatures} />
      <Syllabus heading="Data Science Training Syllabus" Syllabus={syllabus} />




      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for DevOps Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>

        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DevOps</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">Data Science Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>IT Accurate Training Certification will serve as proof that you have completed the courses at IT Accurate.</li>
              <li>The Data Science certification offered by IT Accurate will equip you with valuable skills, boosting your competitiveness in the job market.</li>
              <li>IT Accurate provides complete support for your Data Science global certification, guaranteeing a 100% pass rate in exams like the Data Science Certification, Data Science Platform Development Certification, and other global certifications.</li>
            </ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Marq />
      <CourseOpportunities pageName="DataScience" />
      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

      {/* <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div> */}
      <MarqueeLogos />
    </div>
  );
};

export default AIML;
