import React, { useEffect, useRef } from 'react';
import '../../css/DevOps.css';
import Alumni from '../../components/MarqueeLogos'
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from '../../components/Roadmap';
import WhatsDevOps from '../../components/WhatsDevOps'
import Syllabus from '../../components/Syllabus';
import KeyFeatures from '../../components/KeyFeatures';
import SalaryHikeSection from '../../components/SalaryHike';
import Faq from '../../components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../../components/ContactForm';
import Learn from '../../components/Learn';
import Marq from '../../components/CustomMarquee';
import JobPreparation from '../../components/JobPreparation';
import CourseOpportunities from '../../components/CourseOpportunities';
import BatchesPage from "../../components/Batches";
import StickyNavbar from "../../components/StickyNavbar";
// project slider image
// import dev1 from '../../assets/Projects/Salesforce/1.png';
// import dev2 from '../../assets/Projects/Salesforce/2.png';
// import dev3 from '../../assets/Projects/Salesforce/3.png';
// import dev4 from '../../assets/Projects/Salesforce/4.png';
// import dev5 from '../../assets/Projects/Salesforce/5.png';
// import dev6 from '../../assets/Projects/Salesforce/6.png';
// import dev7 from '../../assets/Projects/Salesforce/7.png';
// import dev8 from '../../assets/Projects/Salesforce/8.png';
// import dev9 from '../../assets/Projects/Salesforce/9.png';
// import dev10 from '../../assets/Projects/Salesforce/10.png';
// import rev1 from '../../assets/Reviews/Salesforce/sf_rev1.png';
// import rev2 from '../../assets/Reviews/Salesforce/sf_rev2.png';
// import rev3 from '../../assets/Reviews/Salesforce/sf_rev3.png';
// import rev4 from '../../assets/Reviews/Salesforce/sf_rev4.png';
// import rev5 from '../../assets/Reviews/Salesforce/sf_rev5.png';
// import RegularSlider from '../../Components/RegularSlider';

const SalesforceAdmin = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'syzh1IxEaak', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'syzh1IxEaak', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is salesforce admin?',
        'Salesforce Admin careers.',
        'Salesforce course.',
        'Salesforce certification.',
        'Salesforce job in India. ',
        'Salesforce job in Thane.',
      ]
    },
    {
      title: ' Understanding Salesforce Basics',
      points: [
        'Learn the basics of Salesforce, including its architecture and data model.',
        'Understand Salesforce objects, fields, relationships, and record types.',
        'Familiarize yourself with the Salesforce user interface and navigation.',
      ]
    },
    {
      title: 'Mastering Salesforce Administration Tools',
      points: [
        'Explore Salesforce setup options and customize the platform to meet business needs.',
        'Learn about user management, roles, profiles, and permission sets.',
        'Dive into security settings, sharing rules, and data validation rules.',
      ]
    },
    {
      title: 'Working with Data and Reports',
      points: [
        'Learn to import, export, and manipulate data in Salesforce using data loader tools.',
        'Create and customize reports and dashboards to visualize data effectively.',
        'Understand data integrity best practices and data maintenance strategies.',
      ]
    },
    {
      title: ' Automation with Workflows and Processes',
      points: [
        'Explore workflow rules and process builder to automate business processes.',
        'Learn about approval processes and how to configure them in Salesforce.',
        'Understand the basics of Salesforce automation tools like flows and triggers.',
      ]
    },
    {
      title: ' Integration and AppExchange',
      points: [
        'Explore Salesforce integration options with external systems and APIs.',
        'Learn about AppExchange apps and how to install and configure them.',
        'Understand the basics of Salesforce Connect and external objects.',
      ]
    },
    {
      title: 'Advanced Topics and Best Practices',
      points: [
        'Explore advanced Salesforce admin topics like Lightning, communities, and mobile apps.',
        'Learn best practices for data, security, and performance in Salesforce.',
        'Stay updated with Salesforce releases, features, and certifications for a competitive edge.',
      ]
    },
    {
      title: 'Job Opportunities',
      points: [
        'Prepare for your Job search.',
        'Ready walkin in India.',
        'Tie up references.',
        'Get placed.',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to Cloud Computing And Salesforce Platform',
      subpoints: [
        'What is Cloud Computing',
        'SAAS (Salesforce.com)',
        'PAAS (Force.com)',
        'IAAS',
        'Introduction to Force.com Platform',
        'Declarative & Programmatic Options in Force.com',
        'Multi-tenant Architecture',
        'Sign-up for a Free Environment / Org',
        'Salesforce System overview',
        'Editions and Different Environments Available',
        'MVC Pattern',
      ],
    },
    {
      title: 'Data Model, Objects, Relationships And Fields',
      subpoints: [
        'Custom Objects and understand various',
        'properties in the Object definition section',
        'Standard objects',
        'Field and Data Types',
        'Custom Field Properties',
        'Field Dependencies',
        'Types of Relationship Fields',
        'Search Layouts',
        'Matching Rule',
        'Duplicate Rule',
      ],
    },
    {
      title: 'Salesforce Security Model And Its Features',
      subpoints: [
        'System Level or Org Level Security',
        'Profile and Permissions Sets',
        'Password Policy',
        'IP Address security',
        'Login hours',
        'Session settings',
        'Activations',
        'Page layout assignment',
        'Tab setting',
        'Role and Role Hierarchy',
        'Public Group',
        'Field level security',
        'Record Level and Object Level',
        'Organization wide defaults',
      ],
    },
    {
      title: 'Business Logic, Formulas & Validations',
      subpoints: [
        'Business Logic Mind Map',
        'Different Options to Implement Business',
        'Logic',
        'Functions',
        'Formula',
        'Validation Rules',
        'Rollup Summary Fields',
        'Record Types and Page Layouts',
      ],
    },
    {
      title: 'Business Process Automation In Salesforce',
      subpoints: [
        'Lightning flow',
        'Screen flow',
        'Trigger flow',
        'Schedule trigger flows',
        'Approval Process',
      ],
    },
    {
      title: 'Customer Management Using Salesforce Sales Cloud',
      subpoints: [
        'Sales Cloud',
        'Sales Process',
        'Accounts and Contacts Management',
        'Opportunities',
        'Activity',
      ],
    },
    {
      title: 'Customer Service Solution Using Salesforce Service Cloud',
      subpoints: [
        'Service Cloud',
        'Sales Cloud',
        'Email Templates',
      ],
    },
    {
      title: 'Data Handling And Processing In Salesforce',
      subpoints: [
        'Data Handling and Processing Overview',
        'Exporting data and Regular Backup',
        'External ID and Unique Fields',
        'Import Data',
        'Apex Data loader',
        'Import Wizard and Data Loader and compare the features',
        'Update',
        'Upsert operations',
        'Delete',
        'Hard Delete records.',
      ],
    },
    {
      title: 'Reports And Dashboards In Salesforcereports And Dashboards In Salesforce',
      subpoints: [
        'Report with and without modifications',
        'Tabular report, summary, Joined',
        'Matrix report',
        'User filters in reports',
        'Dashboard',
        'Bucket field',
      ],
    },
    {
      title: 'Others Basics Introduction',
      subpoints: [
        'MFA(Multifactor Authentication).',
        'AppExchange applications.',
        'Activity management',
        'Einstein Bots',
      ],
    },

  ];

  const keyFeatures = [
    { text: "50+ Hrs Instructor-Led Training", icon: "fa-chalkboard-teacher" },
    { text: "Certificate Guidance", icon: "fa-certificate" },
    { text: "Mentor Support", icon: "fa-user-friends" },
    { text: "Resume Editing", icon: "fa-file-alt" },
    { text: "52 Hrs Project & Exercises", icon: "fa-tasks" },
    { text: "100% Job Oriented Training", icon: "fa-graduation-cap" },
    { text: "69 Hrs Self-Paced Videos", icon: "fa-video" },
    { text: "Job Assistance", icon: "fa-briefcase" },
    { text: "Free Demo Class Available", icon: "fa-chalkboard" },
    { text: "Affordable Fee Structure", icon: "fa-money-bill-wave" },
    { text: "Flexible Schedule", icon: "fa-calendar-alt" },
    { text: "Completed 1482+ Batches", icon: "fa-users" }
  ];


  const faqs = [
    {
      question: "Salesforce administration?",
      answer: "Salesforce administration involves configuring and customizing the Salesforce platform to meet the specific needs of an organization. Admins manage users, security settings, data, and automation to ensure Salesforce operates efficiently and effectively.",
    },
    {
      question: "What are the roles and responsibilities of a Salesforce admin?",
      answer: "A Salesforce admin is responsible for user management, security settings, data maintenance, customization of Salesforce objects, workflows, reports, and dashboards. They also handle troubleshooting, training users, and staying updated with Salesforce releases and features.",
    },
    {
      question: "How can I become a Salesforce admin??",
      answer: "To become a Salesforce admin, you can start by learning Salesforce fundamentals through online courses, official Salesforce documentation, and hands-on practice in a Salesforce environment. Pursuing Salesforce admin certifications, such as Salesforce Certified Administrator, can also enhance your credentials.",
    },
    {
      question: "What are some common challenges faced by Salesforce admins??",
      answer: "Common challenges for Salesforce admins include data quality issues, managing complex automation, user adoption, integrating with external systems, and staying updated with new Salesforce features and best practices.",
    },
    {
      question: "How can I improve data management as a Salesforce admin??",
      answer: "To improve data management, Salesforce admins can implement data validation rules, clean up duplicate records, establish data governance policies, regularly audit data quality, and use data loader tools for import/export tasks.",
    },
    {
      question: "What are best practices for security in Salesforce administration?",
      answer: "Best practices for security include assigning proper user roles and profiles, setting up permission sets, implementing field-level security, enabling two-factor authentication, monitoring login activity, and regularly reviewing and updating security settings.",
    },
    {
      question: "How can I optimize performance in Salesforce as an admin?",
      answer: "Admins can optimize performance by optimizing page layouts, reducing the number of custom fields and workflows, using indexed fields for data queries, enabling caching, minimizing use of formula fields, and regularly monitoring system performance metrics.",
    },
    {
      question: "What resources are available for ongoing learning and support for Salesforce admins?",
      answer: "Salesforce Trailhead offers interactive learning modules and trails for Salesforce admins. Additionally, Salesforce community forums, webinars, user groups, and official Salesforce documentation provide valuable resources for ongoing learning and support.",
    },
  ];
  const whatsData = [
    {
      title: 'What is Salesforce Admin?',
      points: [
        'A Salesforce Admin is responsible for configuring and maintaining the Salesforce platform to align with business needs.',
        'They manage user accounts, security settings, data handling, and customization of Salesforce features.',
        'Salesforce Admins play a crucial role in optimizing workflows, training users, and ensuring the smooth operation of Salesforce within an organization.',
        'Individuals from IT or non-IT backgrounds, freshers, or experienced professionals can all start their career in Salesforce, regardless of their previous experience.',
        'Begin by learning the basics of the platform, get trained with IT Accurate, and join user groups and communities. Placement-oriented training will provide you with hands-on experience.',
        'Becoming proficient in Salesforce is achievable through consistent effort, dedication, and commitment.',
      ]
    }
  ];


  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };


  // const imageList = [
  //   { Image: dev1, alt: "Award 1" },
  //   { Image: dev2, alt: "Award 2" },
  //   { Image: dev3, alt: "Award 3" },
  //   { Image: dev4, alt: "Award 4" },
  //   { Image: dev5, alt: "Award 5" },
  //   { Image: dev6, alt: "Award 6" },
  //   { Image: dev7, alt: "Award 7" },
  //   { Image: dev8, alt: "Award 8" },
  //   { Image: dev9, alt: "Award 8" },
  //   { Image: dev10, alt: "Award 8" },
  // ];

  // const reviewList = [
  //   { Image: rev1, alt: "rev1" },
  //   { Image: rev2, alt: "rev1" },
  //   { Image: rev3, alt: "rev1" },
  //   { Image: rev4, alt: "rev1" },
  //   { Image: rev5, alt: "rev1" },

  // ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
        <h1>IT Accurate - Best Salesforce Training in Thane - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">6489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6667 Learners</span>
          </div>
          <p>
            Salesforce Training by IT Accurate offers the best Salesforce Admin Training in Thane for both freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our training program covers every technical topic in detail, ensuring students gain real-time hands-on experience.
          </p>
          <p>
            Our practical, job-oriented training program not only provides a certification but also equips you with knowledge equivalent to over 10 years of field experience. We value your time, which is why we aim to train you in Salesforce Admin within just 1.5 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-month internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Salesforce software access for practice for every student</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Real-time live project exposure</li>
          </ul>
        </div>

        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <StickyNavbar name="SalesforceAdmin" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="salesforceAdmin" />
      <Roadmap heading="Roadmap to learn salesforce Admin" roadmapData={roadmapData} />
      <KeyFeatures heading="Salesforce administration" keyFeatures={keyFeatures} />
      <Syllabus heading="Salesforce Admin Syllabus" Syllabus={syllabus} />
      <JobPreparation />
      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">Salesforce Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>IT Accurate training certification serves as proof that the courses were successfully completed with IT Accurate.</li>
              <li>The DevOps certification offered by IT Accurate equips you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>
                IT Accurate Admin training institute in Thane provides comprehensive guidance for your Salesforce global certification, ensuring a 100% passing guarantee in examinations such as Salesforce Administration Certification, Salesforce Platform Development Certification, and various other global exams.
              </li>
            </ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>



      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Salesforce Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}
      <CourseOpportunities pageName="SalesforceAdmin" />


      <Marq />

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}
      <Alumni />
    </div>
  );
};

export default SalesforceAdmin;