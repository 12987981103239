import React, { useEffect, useRef } from 'react';
import '../../css/DevOps.css';
import Alumni from '../../components/MarqueeLogos'
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from '../../components/Roadmap';
import WhatsDevOps from '../../components/WhatsDevOps'
import Syllabus from '../../components/Syllabus';
import SalaryHikeSection from '../../components/SalaryHike';
import KeyFeatures from '../../components/KeyFeatures';
import Faq from '../../components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../../components/ContactForm';
import Learn from '../../components/Learn';
import Marq from '../../components/CustomMarquee';
import JobPreparation from '../../components/JobPreparation';
import CourseOpportunities from '../../components/CourseOpportunities';
import BatchesPage from "../../components/Batches";
import StickyNavbar from "../../components/StickyNavbar";
// import dev1 from '../../assets/Projects/Salesforce/1.png';
// import dev2 from '../../assets/Projects/Salesforce/2.png';
// import dev3 from '../../assets/Projects/Salesforce/3.png';
// import dev4 from '../../assets/Projects/Salesforce/4.png';
// import dev5 from '../../assets/Projects/Salesforce/5.png';
// import dev6 from '../../assets/Projects/Salesforce/6.png';
// import dev7 from '../../assets/Projects/Salesforce/7.png';
// import dev8 from '../../assets/Projects/Salesforce/8.png';
// import dev9 from '../../assets/Projects/Salesforce/9.png';
// import dev10 from '../../assets/Projects/Salesforce/10.png';

// import rev1 from '../../assets/Reviews/Salesforce/sf_rev1.png';
// import rev2 from '../../assets/Reviews/Salesforce/sf_rev2.png';
// import rev3 from '../../assets/Reviews/Salesforce/sf_rev3.png';
// import rev4 from '../../assets/Reviews/Salesforce/sf_rev4.png';
// import rev5 from '../../assets/Reviews/Salesforce/sf_rev5.png';
// import RegularSlider from '../../Components/RegularSlider';


const SalesforceDev = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'syzh1IxEaak', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'syzh1IxEaak', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'Salesforce Development Development',
        'What is Salesforce Development?',
        'Salesforce Development Careers',
        'Salesforce Development course',
        'Salesforce Development certification',
        'Salesforce Development job in india',
        'Salesforce Development job in Thane'
      ]
    },
    {
      title: 'Basics of Salesforce Development Development',
      points: [
        'Learn About Salesforce Development Platform basics and architecture',
        'Understand Salesforce Development Data Model, Objects and relationships',
        'Explore Salesforce Development development environments like Developer Console and Salesforce Development DX',
      ]
    },
    {
      title: 'APEX Programming',
      points: [
        'Start with Apex basics such as variables, data types and control structures.',
        'Learn About Apex Classes, triggers and batch processing',
        'Understand How to write test classes and ensure code coverage',
      ]
    },
    {
      title: 'Visualforce and Lightening Components',
      points: [
        'Begin with Visualforce basics for building custom user interfaces',
        'Dive into Lightning Web Components(LWC) development',
        'Explore Lightning Design System(SLDS) for UI Styling',
        'Automate testing and validation of infrastructure changes.'
      ]
    },
    {
      title: 'Integration And APIS ',
      points: [
        'Learn About Salesforce Development APIs such as REST, SOAP and BULK APIs.',
        'Understand how to integrate Salesforce Development with external systems like databases and web services',
        'Explore tools like Salesforce Development Connect and External Objects for Data Intergration',
      ]
    },
    {
      title: 'Salesforce Development APP development',
      points: [
        'Explore Building customs apps using Salesforce Development App builder',
        'Learn about declarative development with Process Builder and Flow Builder.',
        'Understand how to package and deploy apps using Salesforce Development Packaging.',
      ]
    },
    {
      title: 'Advanced Topics and Best Practices',
      points: [
        'Dive into advanced Apex topic like asynchronus processing and governor limits.',
        'Learn About Salesforce Development security best practices and data Management.',
        'Explore Optimization techniques for improving Salesforce Development performance.',
      ]
    },
    {
      title: 'JOB opportunities',
      points: [
        'Preapare for your JOB search',
        'Ready Walkin in India',
        'Tie Up refernces',
        'GET PLACED',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction To APEX Programming',
      subpoints: [
        'Basic Of C language.',
        'What is Cloud Computing',
        'APEX Introduction',
        'APEX Classes',
        'APEX Development Process',
        'APEX Development tools',
        'APEX Governor Limits',
      ],
    },
    {
      title: 'Data Types And Logic Control Statements',
      subpoints: [
        'Overview of Apex Data Types',
        'Primitive Data Types',
        'Non-Primitive Data Types',
        'Object Data Types',
        'Enum Data Types',
        'Collections Data Types',
        'APEX Operators',
        'APEX Control Statements',
        'Executiong APEX Programs',
      ],
    },
    {
      title: 'APEX Classes',
      subpoints: [
        'APEX Classes',
        'Access Modifiers',
        'APEX Class Constructors',
        'APEX Class Variables and Methods',
        'Inheritance, Sharing and Interface',
        'Accessing APEX Class',
        'APEX Keywords and Methods',
      ],
    },
    {
      title: 'Implementing SOQL And SOSL Queries',
      subpoints: [
        'Standard object and Field API Names',
        'Relationships in APEX',
        'SOQL Function, SOQL Bindings and SOQL For Loops',
        'Dynamic Query Creation',
        'Relationship Query and its Types',
        'Child to Parent Querry',
        'Parent to Child Querry',
      ],
    },
    {
      title: 'Data Manipulation Language',
      subpoints: [
        'Data Manipulation Language',
        'Loops in DML',
        'Database DML Options',
        'Transaction Control',
        'Ways to invoke DML Operations',
        'Invoking DML Operations',
        'Handling DML Errors',
      ],
    },
    {
      title: 'Triggers In APEX',
      subpoints: [
        'APEX Triggers and its Types',
        'Order of Execution of Trigger',
        'Trigger Syntax and Events',
        'Trigger Context Variables',
        'Bulkify Triggers',
        'Trigger Helper Class Pattern',
        'Best Practice of Trigger',
      ],
    },
    {
      title: 'Batch Classes',
      subpoints: [
        'Batch Class in APEX',
        'Batch Class Methods',
        'Schedule Methods In Batch Class',
        'Cronn Expressions',
        'Email Programming',
        'Future Methods',
        'Asynchronous APEX VS Synchronous APEX',
      ],
    },
    {
      title: 'Exception Handling',
      subpoints: [
        'Exeptions',
        'Exception Statements',
        'System-Defined Exception',
        'Catching Different Exception Types',
        'Try Catch and Finally Methods',
      ],
    },
    {
      title: 'APEX Testing Essentials',
      subpoints: [
        'Write and run APEX Tests',
        'Create Tests Data',
        'Running Test Units',
        'Testing Best Practices',
        'Code Coverage',
        'Test Classes Methods',
      ],
    },

  ];

  const keyFeatures = [
    { text: "70+ Hrs Instructor-Led Training", icon: "fa-chalkboard-teacher" },
    { text: "Certificate Guidance", icon: "fa-certificate" },
    { text: "Mentor Support", icon: "fa-user-friends" },
    { text: "Resume Editing", icon: "fa-file-alt" },
    { text: "52 Hrs Project & Exercises", icon: "fa-tasks" },
    { text: "100% Job Oriented Training", icon: "fa-graduation-cap" },
    { text: "36 Hrs Self-Paced Videos", icon: "fa-video" },
    { text: "Job Assistance", icon: "fa-briefcase" },
    { text: "Free Demo Class Available", icon: "fa-chalkboard" },
    { text: "Affordable Fee Structure", icon: "fa-money-bill-wave" },
    { text: "Flexible Schedule", icon: "fa-calendar-alt" },
    { text: "Completed 1500+ Batches", icon: "fa-users" }
  ];

  const faqs = [
    {
      question: "What is Salesforce Development Development?",
      answer: "Salesforce Development Development involves customizing the Salesforce Development platform to meet specific business needs through coding, integration, and app development.",
    },
    {
      question: "What Programming Language is used in Salesforce Development Development ?",
      answer: "Apex is the primary programming language used in Salesforce Development Development, similar to Java, for creating custom logic and functionalities.",
    },
    {
      question: "What is Visualforce in Salesforce Development ?",
      answer: "Visualforce is a markup language used to develop custom user interfaces in Salesforce Development, allowing developers to create custom pages and components.",
    },
    {
      question: "What are Lightning Components?",
      answer: "Lightning Components are a modern framework for building reusable components in Salesforce Development, providing a responsive and efficient user experience.",
    },
    {
      question: "How do I start Learning Salesforce Development Development?",
      answer: "To start learning Salesforce Development Development, begin with understanding Salesforce Development basics, then progress to Apex programming, Visualforce, Lightning Components, and integrations.",
    },
    {
      question: "What is Salesforce Development DX?",
      answer: "Salesforce Development DX (Developer Experience) is a set of tools and features designed to streamline the development lifecycle on the Salesforce Development platform, including version control, scratch orgs, and continuous integration.",
    },
    {
      question: "How can I test Salesforce Development Code?",
      answer: "Salesforce Development developers test their code using Apex test classes to ensure functionality and meet code coverage requirements for deployment.",
    },
    {
      question: "What is the AppExchange in Salesforce Development?",
      answer: "The AppExchange is Salesforce Development’s marketplace for third-party applications and solutions that extend the functionality of the Salesforce Development platform, including apps for sales, marketing, customer service and more.",
    },
    {
      question: "What are Salesforce Development Triggers?",
      answer: "Salesforce Development Triggers are Apex code that executes before or after specific events occur in Salesforce Development, such as record insertion, update, deletion, or undeletion. Triggers allow developers to automate business processes and enforce data integrity within Salesforce Development.",
    },
  ];

  const whatsData = [
    {
      title: 'What is Salesforce Development?',
      points: [
        'Salesforce Development involves customizing Salesforce to meet unique business needs.',
        'It includes coding in languages like Apex and integrating with external systems.',
        'Developers can publish apps on Salesforce’s AppExchange marketplace.',
        'Individuals from IT or non-IT backgrounds, freshers, or experienced professionals can all start their career in Salesforce Development, regardless of their prior experience.',
        'Begin by learning the basics of the platform, get trained with IT Accurate, and join user groups, communities. Placement-oriented training in Salesforce Development will provide you with hands-on experience.',
        'Becoming proficient in Salesforce Development is achievable through consistent effort, dedication, and commitment.',
      ]
    }
  ];


  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };


  // const imageList = [
  //   { Image: dev1, alt: "Award 1" },
  //   { Image: dev2, alt: "Award 2" },
  //   { Image: dev3, alt: "Award 3" },
  //   { Image: dev4, alt: "Award 4" },
  //   { Image: dev5, alt: "Award 5" },
  //   { Image: dev6, alt: "Award 6" },
  //   { Image: dev7, alt: "Award 7" },
  //   { Image: dev8, alt: "Award 8" },
  //   { Image: dev9, alt: "Award 8" },
  //   { Image: dev10, alt: "Award 8" },
  // ];

  // const reviewList = [
  //   { Image: rev1, alt: "rev1" },
  //   { Image: rev2, alt: "rev1" },
  //   { Image: rev3, alt: "rev1" },
  //   { Image: rev4, alt: "rev1" },
  //   { Image: rev5, alt: "rev1" },
  // ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
        <h1>IT Accurate - Best Salesforce Development Training in Thane - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.9)</span>
            <span className="separator">|</span>
            <span className="ratings-count">6489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">7367 Learners</span>
          </div>
          <p>
            Salesforce Development Training by IT Accurate offers the best Salesforce Development Training in Thane for both freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our training program covers every technical topic in detail, ensuring students gain real-time hands-on experience.
          </p>
          <p>
            Our practical, job-oriented training program not only provides a certification but also equips you with knowledge equivalent to over 7 years of field experience. We value your time, which is why we aim to train you in Salesforce Development within just 2 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-month internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
          </ul>
        </div>

        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <StickyNavbar name="SalesforceDevelopment" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="salesforceDevlopment" />
      <Roadmap heading="Roadmap to Learn Salesforce Development" roadmapData={roadmapData} />
      <JobPreparation />
      <Syllabus heading="Salesforce Development Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Salesforce Developement Training" keyFeatures={keyFeatures} />
      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">Salesforce Development Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul className="points-list">
              <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
              <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
              <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
              <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}
      <CourseOpportunities pageName="Salesforce" />
      <Marq />

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}
      <Alumni />

    </div>
  );
};

export default SalesforceDev;