import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Navbar from './components/Navbar';
import BlogList from './Blog/BlogList';
import BlogDetails from './Blog/BlogDetails';
import CarrerDev from './components/CarrerDev';
import WhyUs from './components/WhyUs';
import MarqueeLogos from './components/MarqueeLogos';
import Brands from './components/Brands';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Floating from './components/Floating';
import DevOpsPage from './pages/Devops';
import AWS from './pages/AWS';
import SAP from './pages/Sap/Sap';
import SAPABAP from './pages/Sap/SapABAP';
import SAPARIBA from './pages/Sap/SapAriba';
import SAPBASIS from './pages/Sap/SapBasis';
import SAPFICO from './pages/Sap/SapFico';
import SAPFIORI from './pages/Sap/SapFiori';
import SAPHCM from './pages/Sap/SapHcm';
import SAPSUCCESSFACTORS from './pages/Sap/SAPSUCCESSFACTORS';
import SAPLESL from './pages/Sap/SapLESL';
import SAPMM from './pages/Sap/SapMM';
import SAPPM from './pages/Sap/SapPM';
import SAPPP from './pages/Sap/SapPP';
import SAPQM from './pages/Sap/SapQM';
import SAPSCM from './pages/Sap/SapSCM';
import SAPSD from './pages/Sap/SapSD';
import SAPWMEWM from './pages/Sap/SapWMEWM';
import ML from './pages/MachineLearning';
import AIML from './pages/AIML';
import DA from './pages/DataAnalytics';
import BA from './pages/BusinessAnalytics';
import DS from './pages/DataScience';
import FSD from './pages/FullStackDev';
import Salesforce from './pages/Salesforce';
import SalesforceAdmin from './pages/Salesforce/SalesforceAdmin';
import SalesforceDevelopment from './pages/Salesforce/SalesforceDevlopment';
import SalesforceLWC from './pages/Salesforce/SalesforceLWC';
import SalesforceMarketing from './pages/Salesforce/SalesforceMarketing';
import MachineLearning from './pages/MachineLearning';
import Python from './pages/Python';
import TermsPage from './pages/Terms';
import PrivacyPage from './pages/Privacy';
import RefundPage from './pages/Refund';
import PopUpForm from './components/PopUpForm';
import PowerBI from './pages/DataVisualization/PowerBI';
import Excel from './pages/DataVisualization/AdvancedExcel';
import Tableau from './pages/DataVisualization/Tableau';
import Placed from './pages/PlacedStudents';
import BatchesPage from './components/Batches';
import { ModalProvider } from './context/ModelContext';
import GlobalModal from './components/GlobalModel'; // Ensure correct import for GlobalModal
import Learn from './components/Learn';
import SalaryHikeSection from './components/SalaryHike';
import ServiceNow from './pages/ServiceNow';
import SAPHR from './pages/HR Training/SAPHR';
import BAHR from './pages/HR Training/BAHR';
import DAHR from './pages/HR Training/DAHR';

function App() {
  return (
    <ModalProvider>
      <Router>
        <Navbar />
        <Floating />
        <GlobalModal />
        <div className="App">
          <PopUpForm />
          <Routes>
            <Route path='/blogDetails/:id' element={<BlogDetails />} />
            <Route path='/blog' element={<BlogList />} />
            <Route path="/" element={<Home />} />
            <Route path="/devops" element={<DevOpsPage />} />
            <Route path="/aws" element={<AWS />} />
            <Route path="/ml" element={<ML />} />
            <Route path="/aiml" element={<AIML />} />
            <Route path="/da" element={<DA />} />
            <Route path="/ba" element={<BA />} />
            <Route path="/ds" element={<DS />} />
            <Route path="/fsd" element={<FSD />} />
            <Route path="/about" element={<About />} />
            <Route path="/carrerdev" element={<CarrerDev />} />
            <Route path="/batch" element={<BatchesPage />} />
            <Route path="/WhyUs" element={<WhyUs />} />
            <Route path="/MarqueeLogos" element={<MarqueeLogos />} />
            <Route path="/Brands" element={<Brands />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/sap" element={<SAP />} />
            <Route path="/sap-abap" element={<SAPABAP />} />
            <Route path="/sap-ariba" element={<SAPARIBA />} />
            <Route path="/sap-basis" element={<SAPBASIS />} />
            <Route path="/sap-fico" element={<SAPFICO />} />
            <Route path="/sap-fiori" element={<SAPFIORI />} />
            <Route path="/sap-hcm" element={<SAPHCM />} />
            <Route path='/sap-successfactors' element={<SAPSUCCESSFACTORS />} />
            <Route path="/sap-lesl" element={<SAPLESL />} />
            <Route path="/sap-mm" element={<SAPMM />} />
            <Route path="/sap-pm" element={<SAPPM />} />
            <Route path="/sap-pp" element={<SAPPP />} />
            <Route path="/sap-qm" element={<SAPQM />} />
            <Route path="/sap-scm" element={<SAPSCM />} />
            <Route path="/sap-sd" element={<SAPSD />} />
            <Route path="/sap-wmewm" element={<SAPWMEWM />} />
            <Route path='/salesforce' element={<Salesforce />} />
            <Route path='/salesforce-admin' element={<SalesforceAdmin />} />
            <Route path='/salesforce-development' element={<SalesforceDevelopment />} />
            <Route path='/salesforce-lwc' element={<SalesforceLWC />} />
            <Route path='/salesforce-marketing' element={<SalesforceMarketing />} />
            <Route path='/machine-learning' element={<MachineLearning />} />
            <Route path='/python' element={<Python />} />
            <Route path='/terms' element={<TermsPage />} />
            <Route path='/privacy' element={<PrivacyPage />} />
            <Route path='/refund' element={<RefundPage />} />
            <Route path='/powerbi' element={<PowerBI />} />
            <Route path='/excel' element={<Excel />} />
            <Route path='/tableau' element={<Tableau />} />
            <Route path='/placed' element={<Placed />} />
            <Route path="/learn" element={<Learn />} />
            <Route path="/salaryhike" element={<SalaryHikeSection />} />
            <Route path="/servicenow" element={<ServiceNow />} />
            <Route path="/sap-hr" element={<SAPHR />} />
            <Route path="/da-hr" element={<BAHR/>} />
            <Route path="/ba-hr" element={<DAHR />} />

          </Routes>
        </div>
        <Footer />
      </Router>
    </ModalProvider>
  );
}

export default App;
